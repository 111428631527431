import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from 'recharts';

const MetricCard = ({ title, value, trend, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h3 className="font-semibold mb-2">{title}</h3>
    <div className="text-3xl font-bold">{value}</div>
    {trend && (
      <div className="flex items-center text-green-500 mt-2">
        <svg
          className="w-4 h-4 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
          />
        </svg>
        {trend}
      </div>
    )}
    {children}
  </div>
);

export default function OEEDashboard() {
  
  const plants = [
    'California Plant',
    'Texas Plant',
    'Florida Plant',
    'New York Plant',
    'Illinois Plant',
    'Ohio Plant',
    'Georgia Plant',
    'Michigan Plant',
    'North Carolina Plant',
    'Pennsylvania Plant',
    'Washington Plant',
    'Arizona Plant',
  ];

  const plantData = {
  'California Plant': {
    beforeData: {
      availability: 83.3,
      performance: 90,
      quality: 96,
      oee: 72,
    },
    afterData: {
      availability: 90.7,
      performance: 95,
      quality: 96,
      oee: 83,
    },
    annualDowntimeBefore: 800,
    annualDowntimeAfter: 464,
    trend: '+15% improvement',
    timeline: [
      { date: 'Dec 15, 2024', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Nov 1, 2024', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Sep 15, 2024', event: 'Initial Deployment', status: 'in-progress' },
      { date: 'Aug 1, 2024', event: 'Pilot Program Start', status: 'completed' }
    ]
  },
  'Texas Plant': {
    beforeData: {
      availability: 82.0,
      performance: 89,
      quality: 95,
      oee: 71,
    },
    afterData: {
      availability: 91.5,
      performance: 94,
      quality: 97,
      oee: 84,
    },
    annualDowntimeBefore: 780,
    annualDowntimeAfter: 432,
    trend: '+16% improvement',
    timeline: [
      { date: 'Jan 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Dec 1, 2024', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Oct 15, 2024', event: 'Initial Deployment', status: 'in-progress' },
      { date: 'Sep 1, 2024', event: 'Pilot Program Start', status: 'completed' }
    ]
  },
  'Florida Plant': {
    beforeData: {
      availability: 80.5,
      performance: 88,
      quality: 94,
      oee: 70,
    },
    afterData: {
      availability: 89.2,
      performance: 93,
      quality: 95,
      oee: 81,
    },
    annualDowntimeBefore: 850,
    annualDowntimeAfter: 495,
    trend: '+13% improvement',
    timeline: [
      { date: 'Feb 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Jan 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Nov 15, 2024', event: 'Initial Deployment', status: 'pending' },
      { date: 'Oct 1, 2024', event: 'Pilot Program Start', status: 'in-progress' }
    ]
  },
  'New York Plant': {
    beforeData: {
      availability: 81.0,
      performance: 87,
      quality: 93,
      oee: 69,
    },
    afterData: {
      availability: 88.5,
      performance: 92,
      quality: 94,
      oee: 80,
    },
    annualDowntimeBefore: 820,
    annualDowntimeAfter: 510,
    trend: '+14% improvement',
    timeline: [
      { date: 'Mar 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Feb 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Dec 15, 2024', event: 'Initial Deployment', status: 'pending' },
      { date: 'Nov 1, 2024', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Illinois Plant': {
    beforeData: {
      availability: 79.5,
      performance: 86,
      quality: 92,
      oee: 68,
    },
    afterData: {
      availability: 87.8,
      performance: 91,
      quality: 93,
      oee: 79,
    },
    annualDowntimeBefore: 840,
    annualDowntimeAfter: 525,
    trend: '+12% improvement',
    timeline: [
      { date: 'Apr 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Mar 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Jan 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Dec 1, 2024', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Ohio Plant': {
    beforeData: {
      availability: 82.5,
      performance: 88,
      quality: 94,
      oee: 71,
    },
    afterData: {
      availability: 90.0,
      performance: 93,
      quality: 95,
      oee: 82,
    },
    annualDowntimeBefore: 790,
    annualDowntimeAfter: 455,
    trend: '+15% improvement',
    timeline: [
      { date: 'May 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Apr 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Feb 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Jan 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Georgia Plant': {
    beforeData: {
      availability: 81.8,
      performance: 87,
      quality: 93,
      oee: 70,
    },
    afterData: {
      availability: 89.5,
      performance: 92,
      quality: 94,
      oee: 81,
    },
    annualDowntimeBefore: 810,
    annualDowntimeAfter: 485,
    trend: '+14% improvement',
    timeline: [
      { date: 'Jun 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'May 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Mar 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Feb 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Michigan Plant': {
    beforeData: {
      availability: 80.0,
      performance: 86,
      quality: 92,
      oee: 69,
    },
    afterData: {
      availability: 88.0,
      performance: 91,
      quality: 93,
      oee: 80,
    },
    annualDowntimeBefore: 830,
    annualDowntimeAfter: 515,
    trend: '+13% improvement',
    timeline: [
      { date: 'Jul 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Jun 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Apr 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Mar 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'North Carolina Plant': {
    beforeData: {
      availability: 82.8,
      performance: 89,
      quality: 95,
      oee: 72,
    },
    afterData: {
      availability: 91.0,
      performance: 94,
      quality: 96,
      oee: 83,
    },
    annualDowntimeBefore: 775,
    annualDowntimeAfter: 445,
    trend: '+15% improvement',
    timeline: [
      { date: 'Aug 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Jul 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'May 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Apr 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Pennsylvania Plant': {
    beforeData: {
      availability: 81.2,
      performance: 87,
      quality: 93,
      oee: 70,
    },
    afterData: {
      availability: 89.0,
      performance: 92,
      quality: 94,
      oee: 81,
    },
    annualDowntimeBefore: 815,
    annualDowntimeAfter: 490,
    trend: '+14% improvement',
    timeline: [
      { date: 'Sep 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Aug 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Jun 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'May 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Washington Plant': {
    beforeData: {
      availability: 80.8,
      performance: 86,
      quality: 92,
      oee: 69,
    },
    afterData: {
      availability: 88.5,
      performance: 91,
      quality: 93,
      oee: 80,
    },
    annualDowntimeBefore: 825,
    annualDowntimeAfter: 505,
    trend: '+13% improvement',
    timeline: [
      { date: 'Oct 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Sep 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Jul 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Jun 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  },
  'Arizona Plant': {
    beforeData: {
      availability: 79.0,
      performance: 85,
      quality: 91,
      oee: 67,
    },
    afterData: {
      availability: 87.0,
      performance: 90,
      quality: 92,
      oee: 78,
    },
    annualDowntimeBefore: 860,
    annualDowntimeAfter: 535,
    trend: '+11% improvement',
    timeline: [
      { date: 'Nov 15, 2025', event: 'AI Agent Optimization Complete', status: 'pending' },
      { date: 'Oct 1, 2025', event: 'Phase 2 Implementation', status: 'pending' },
      { date: 'Aug 15, 2025', event: 'Initial Deployment', status: 'pending' },
      { date: 'Jul 1, 2025', event: 'Pilot Program Start', status: 'pending' }
    ]
  }
};

  // Add this after your plantData object
const defaultPlantData = {
  beforeData: {
    availability: 80.0,
    performance: 85,
    quality: 90,
    oee: 70,
  },
  afterData: {
    availability: 88.0,
    performance: 92,
    quality: 94,
    oee: 80,
  },
  annualDowntimeBefore: 800,
  annualDowntimeAfter: 500,
  trend: '+10% improvement'
};

  const [selectedPlant, setSelectedPlant] = useState('California Plant');
  const [currentPlantData, setCurrentPlantData] = useState(plantData['California Plant']);
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
    setCurrentPlantData(plantData['California Plant']);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedPlant) {
      setCurrentPlantData(plantData[selectedPlant] || defaultPlantData);
    }
  }, [selectedPlant]);
  const timelineData = [
    {
      date: 'Dec 15, 2024',
      event: 'AI Agent Optimization Complete',
    },
    {
      date: 'Nov 1, 2024',
      event: 'Phase 2 Implementation',
    },
    {
      date: 'Sep 15, 2024',
      event: 'Initial Deployment',
    },
    {
      date: 'Aug 1, 2024',
      event: 'Pilot Program Start',
    },
  ];

  const comparisonData = currentPlantData ? [
  {
    name: 'Availability',
    before: currentPlantData.beforeData.availability,
    after: currentPlantData.afterData.availability,
  },
  {
    name: 'Performance',
    before: currentPlantData.beforeData.performance,
    after: currentPlantData.afterData.performance,
  },
  {
    name: 'Quality',
    before: currentPlantData.beforeData.quality,
    after: currentPlantData.afterData.quality,
  },
  {
    name: 'Overall OEE',
    before: currentPlantData.beforeData.oee,
    after: currentPlantData.afterData.oee,
  },
] : [];

const radarData = currentPlantData ? [
  { 
    subject: 'Availability', 
    before: currentPlantData.beforeData.availability, 
    after: currentPlantData.afterData.availability 
  },
  { 
    subject: 'Performance', 
    before: currentPlantData.beforeData.performance, 
    after: currentPlantData.afterData.performance 
  },
  { 
    subject: 'Quality', 
    before: currentPlantData.beforeData.quality, 
    after: currentPlantData.afterData.quality 
  },
] : [];

  const handlePlantSelection = (plant) => {
  console.log("Selecting plant:", plant);
  setSelectedPlant(plant);
  console.log("Current plant data:", plantData[plant] || defaultPlantData);
};



  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <button
          className="text-xl font-bold py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          onClick={() => window.location.href = '/factory_demo'}
        >
          ← Back to Dashboard
        </button>
      </div>

      {/* Plant Selection and Timeline */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {/* Plant Selection */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Food Manufacturing Plants</h3>
          <div className="space-y-2">
            {plants.map((plant) => (
              <button
                key={plant}
                className={`w-full text-left px-4 py-2 rounded ${
                  selectedPlant === plant
                    ? 'bg-blue-600 text-white'
                    : 'hover:bg-gray-100'
                }`}
                onClick={() => setSelectedPlant(plant)}
              >
                {plant}
              </button>
            ))}
          </div>
        </div>

        {/* Dashboard Title and Metrics */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold text-center mb-2">
            Manufacturing Plant OEE Dashboard
          </h1>
          <p className="text-center text-gray-600">
            Impact Analysis of AI Agent Deployment
          </p>
          <p className="text-center text-blue-600 font-semibold mt-2">
            {selectedPlant}
          </p>

          {/* Key Metrics Section */}
          {currentPlantData && (
            <div className="grid grid-cols-3 gap-4 mt-4 pt-4 border-t border-gray-200">
              <div className="text-center">
                <p className="text-sm text-gray-600">OEE Improvement</p>
                <p className="text-lg font-bold text-green-600">{currentPlantData.trend}</p>
              </div>
              <div className="text-center border-x border-gray-200">
                <p className="text-sm text-gray-600">Downtime Reduced</p>
                <p className="text-lg font-bold text-blue-600">
                  {currentPlantData.annualDowntimeBefore - currentPlantData.annualDowntimeAfter} hrs/year
                </p>
              </div>
              <div className="text-center">
                <p className="text-sm text-gray-600">Cost Savings</p>
                <p className="text-lg font-bold text-purple-600">
                  ${((currentPlantData.annualDowntimeBefore - currentPlantData.annualDowntimeAfter) * 1000).toLocaleString()}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Timeline */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Implementation Timeline</h3>
          <div className="space-y-4">
            {currentPlantData?.timeline.map((item, index) => (
              <div key={index} className="relative pl-6 border-l-2 border-blue-500">
                <div className={`absolute left-[-5px] top-2 w-2 h-2 rounded-full ${
                  item.status === 'completed' ? 'bg-green-500' :
                  item.status === 'in-progress' ? 'bg-blue-500' :
                  'bg-gray-500'
                }`}></div>
                <div className="text-sm text-gray-600">{item.date}</div>
                <div className="font-medium">{item.event}</div>
                <div className={`text-sm ${
                  item.status === 'completed' ? 'text-green-600' :
                  item.status === 'in-progress' ? 'text-blue-600' :
                  'text-gray-600'
                }`}>
                  {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Before vs After Comparison */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 text-center">Before AI Agent</h3>
          {currentPlantData && (
  <div className="grid grid-cols-2 gap-4 mb-6">
    <MetricCard 
      title="Overall OEE" 
      value={`${currentPlantData.beforeData.oee}%`} 
    />
    <MetricCard 
      title="Annual Downtime" 
      value={`${currentPlantData.annualDowntimeBefore} hrs`} 
    />
  </div>
)}
          <ResponsiveContainer width="100%" height={300}>
            <RadarChart data={radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 100]} />
              <Radar
                name="Before"
                dataKey="before"
                stroke="#2563eb"
                fill="#2563eb"
                fillOpacity={0.2}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 text-center">After AI Agent</h3>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <MetricCard 
              title="Overall OEE" 
              value={`${currentPlantData.afterData.oee}%`}
              trend={currentPlantData.trend}
            />
            <MetricCard 
              title="Annual Downtime" 
              value={`${currentPlantData.annualDowntimeAfter} hrs`} 
            />
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <RadarChart data={radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 100]} />
              <Radar
                name="After"
                dataKey="after"
                stroke="#16a34a"
                fill="#16a34a"
                fillOpacity={0.2}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* OEE Components Comparison */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold mb-4">OEE Components Comparison</h3>
        <ResponsiveContainer width="100%" height={300}>
  {currentPlantData && radarData.length > 0 ? (
    <RadarChart data={radarData}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={30} domain={[0, 100]} />
      <Radar
        name="After"
        dataKey="after"
        stroke="#16a34a"
        fill="#16a34a"
        fillOpacity={0.2}
      />
    </RadarChart>
  ) : (
    <div className="flex items-center justify-center h-full">
      <p>Loading data...</p>
    </div>
  )}
</ResponsiveContainer>
      </div>
    </div>
  );
}
