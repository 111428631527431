import React from 'react';
import {
  LineChart, Line, AreaChart, Area,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, BarChart, Bar
} from 'recharts';

// ---- Sample Data Structures ----
const AIDowntimeData = {
  traditionalDowntime: {
    title: "Equipment & Process Downtime",
    reduction: "45%",
    annualSavings: "$5.1M",
    aiSolutions: [
      {
        name: "Predictive Maintenance AI",
        impact: "Reduced unexpected failures by 70%",
        howItWorks: [
          "Real-time sensor monitoring of 1200+ points",
          "Early detection of anomalies",
          "Predictive maintenance scheduling",
          "Automated work order generation"
        ],
        caseStudy: {
          before: {
            issue: "Monthly equipment failure",
            cost: "$50,000/incident",
            downtime: "48 hours"
          },
          after: {
            prevention: "85% failures prevented",
            savings: "$425,000/month",
            downtime: "7 hours"
          }
        },
        metrics: {
          sensorsCovered: 1200,
          alertsGenerated: 450,
          failuresPrevented: 85,
          costSaved: 425000
        }
      },
      {
        name: "Process Optimization AI",
        impact: "Improved efficiency by 65%",
        howItWorks: [
          "Real-time process parameter optimization",
          "Quality deviation prediction",
          "Automated adjustments",
          "Performance trending"
        ]
      }
    ],
    monthlyData: [
      { month: 'Jan', traditional: 100, withAI: 40 },
      { month: 'Feb', traditional: 95, withAI: 35 },
      { month: 'Mar', traditional: 90, withAI: 30 },
      { month: 'Apr', traditional: 85, withAI: 28 },
      { month: 'May', traditional: 80, withAI: 25 },
      { month: 'Jun', traditional: 75, withAI: 22 }
    ]
  },

  humanFactorDowntime: {
    title: "Human-Factor Downtime",
    reduction: "75%",
    annualSavings: "$3.2M",
    aiSolutions: [
      {
        name: "Digital Worker Assistant",
        impact: "Reduced operational errors by 85%",
        howItWorks: [
          "Step-by-step visual guides",
          "Real-time error detection",
          "Voice-activated assistance",
          "AR-powered maintenance support"
        ],
        metrics: {
          trainingTime: "Reduced by 60%",
          errorRate: "Decreased by 85%",
          resolutionSpeed: "Improved by 70%"
        }
      },
      {
        name: "Workforce Optimization AI",
        impact: "Improved labor efficiency by 65%",
        features: [
          "Smart shift scheduling",
          "Skill-based task assignment",
          "Real-time workload balancing",
          "Performance analytics"
        ]
      }
    ],
    monthlyData: [
      { month: 'Jan', manual: 100, withAI: 25 },
      { month: 'Feb', manual: 95, withAI: 22 },
      { month: 'Mar', manual: 90, withAI: 20 },
      { month: 'Apr', manual: 85, withAI: 18 },
      { month: 'May', manual: 80, withAI: 15 },
      { month: 'Jun', manual: 75, withAI: 12 }
    ]
  },

  supplyChainDowntime: {
    title: "Supply Chain Downtime",
    reduction: "60%",
    annualSavings: "$2.8M",
    aiSolutions: [
      {
        name: "Inventory Management AI",
        impact: "Eliminated stockout delays",
        capabilities: [
          {
            feature: "Predictive Ordering",
            benefit: "Orders placed 2 weeks before potential stockout",
            reduction: "95% stockout elimination"
          },
          {
            feature: "Supplier Risk Analysis",
            benefit: "Early warning of supply chain disruptions",
            reduction: "75% supplier-related delays"
          }
        ]
      },
      {
        name: "Supply Chain Digital Twin",
        impact: "Real-time supply chain visualization",
        features: [
          "End-to-end visibility",
          "Bottleneck prediction",
          "Alternative supplier recommendations",
          "Automated contingency planning"
        ]
      }
    ],
    monthlyData: [
      { month: 'Jan', traditional: 100, withAI: 35 },
      { month: 'Feb', traditional: 95, withAI: 32 },
      { month: 'Mar', traditional: 90, withAI: 30 },
      { month: 'Apr', traditional: 85, withAI: 28 },
      { month: 'May', traditional: 80, withAI: 25 },
      { month: 'Jun', traditional: 75, withAI: 22 }
    ]
  }
};

// Update the digitalWorkforceData structure to better highlight AI integration
const digitalWorkforceData = {
  overallImpact: {
    productionUptime: "99.8%",
    manualTasksAutomated: 85,
    annualSavings: "$1.8M",
    workforceEfficiency: "24/7",
    aiIntegration: {
      predictiveMaintenance: "92% accurate",
      realTimeOptimization: "continuous",
      selfLearning: "improves daily"
    }
  },
  roboticSystems: [
    {
      name: "Material Handling Robot + AI",
      type: "Collaborative Robot",
      aiFeatures: [
        "Self-adjusting pick-and-place patterns",
        "Real-time obstacle avoidance",
        "Adaptive speed control",
        "Predictive maintenance alerts"
      ],
      tasks: [
        {
          name: "Ingredient dispensing",
          aiCapability: "Precise measurement and contamination prevention",
          downtimeReduction: "95%"
        },
        {
          name: "Tray management",
          aiCapability: "Dynamic stack height optimization",
          downtimeReduction: "98%"
        },
        {
          name: "Package handling",
          aiCapability: "Adaptive grip strength and positioning",
          downtimeReduction: "94%"
        }
      ],
      impact: {
        downtimeReduction: "92%",
        speedImprovement: "3x",
        errorRate: "0.001%",
        continuousLearning: "Improves handling patterns daily"
      }
    },
    {
      name: "Quality Control AI Robot",
      type: "Vision-Guided Robot",
      aiFeatures: [
        "Multi-spectrum defect detection",
        "Self-improving inspection algorithms",
        "Automated reporting and trending",
        "Predictive quality alerts"
      ],
      tasks: [
        {
          name: "Visual inspection",
          aiCapability: "Deep learning-based defect detection",
          accuracy: "99.99%"
        },
        {
          name: "Weight verification",
          aiCapability: "Dynamic calibration and adjustment",
          accuracy: "99.999%"
        },
        {
          name: "Real-time quality monitoring",
          aiCapability: "Trend analysis and predictive alerts",
          prevention: "98% defects prevented"
        }
      ],
      impact: {
        accuracyRate: "99.99%",
        inspectionSpeed: "5x human speed",
        qualityImprovements: "Continuous AI learning"
      }
    }
  ],
  aiHighlights: [
    {
      feature: "Autonomous Decision Making",
      description: "AI agents make real-time adjustments to prevent downtime",
      example: "Automatically adjusting pick-up speed based on material properties"
    },
    {
      feature: "Predictive Maintenance",
      description: "AI predicts potential failures before they occur",
      example: "Detecting unusual robot arm movements and scheduling maintenance"
    },
    {
      feature: "Continuous Learning",
      description: "System improves performance through machine learning",
      example: "Optimizing movement patterns based on historical success rates"
    }
  ]
};

// ---- Main Dashboard Component ----
export default function DowntimeReductionDashboard() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <button
          className="text-xl font-bold py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          onClick={() => window.location.href = '/factory_demo'}
        >
          ← Back to Dashboard
        </button>
      </div>

      {/* Overall Impact Section */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          AI-Powered Downtime Reduction
        </h2>
        <div className="grid grid-cols-3 gap-6">
          <div className="text-center p-4 bg-blue-50 rounded-lg">
            <p className="text-sm text-gray-600">Traditional Downtime</p>
            <p className="text-3xl font-bold text-blue-600">-45%</p>
            <p className="text-sm text-blue-600">Equipment & Process</p>
          </div>
          <div className="text-center p-4 bg-green-50 rounded-lg">
            <p className="text-sm text-gray-600">Human Factor</p>
            <p className="text-3xl font-bold text-green-600">-75%</p>
            <p className="text-sm text-green-600">Operational Efficiency</p>
          </div>
          <div className="text-center p-4 bg-purple-50 rounded-lg">
            <p className="text-sm text-gray-600">Supply Chain</p>
            <p className="text-3xl font-bold text-purple-600">-60%</p>
            <p className="text-sm text-purple-600">Logistics & Inventory</p>
          </div>
        </div>
      </div>

      {/* Equipment & Process AI Monitoring */}
<div className="bg-white p-6 rounded-lg shadow-lg mb-8">
  <div className="flex justify-between items-center mb-6">
    <div>
      <h3 className="text-xl font-bold">Smart Equipment Monitoring</h3>
      <p className="text-gray-600">AI-Powered Predictive Maintenance</p>
    </div>
    <div className="flex items-center space-x-2">
      <div className="px-3 py-1 bg-green-100 text-green-700 rounded-full flex items-center">
        <div className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
        45% Downtime Reduction
      </div>
      <div className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full">
        $5.1M Annual Savings
      </div>
    </div>
  </div>

  {/* Real Machine Monitoring */}
  <div className="grid grid-cols-2 gap-8 mb-8">
    <div className="bg-blue-50 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-semibold text-blue-800">Chocolate Production Line #1</h4>
        <span className="px-3 py-1 bg-green-100 text-green-700 rounded-full text-sm">
          98% Efficiency
        </span>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg">
          <p className="text-sm text-gray-600">Real-time Monitoring</p>
          <p className="text-2xl font-bold text-blue-600">1,200+</p>
          <p className="text-xs text-blue-500">Active Sensors</p>
        </div>
        <div className="bg-white p-4 rounded-lg">
          <p className="text-sm text-gray-600">AI Predictions</p>
          <p className="text-2xl font-bold text-blue-600">15</p>
          <p className="text-xs text-blue-500">Days Advance Warning</p>
        </div>
      </div>

      {/* Critical Machine Components */}
      <div className="space-y-4">
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="font-medium">Tempering Machine</span>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Operation: 16hrs</span>
              <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Temperature</span>
              <div className="flex items-center space-x-2">
                <span className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-sm">
                  31.5°C
                </span>
                <span className="text-xs text-gray-500">Target: 31-32°C</span>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Motor Vibration</span>
              <div className="flex items-center space-x-2">
                <span className="px-2 py-1 bg-yellow-100 text-yellow-700 rounded-full text-sm">
                  12.5mm/s
                </span>
                <span className="text-xs text-gray-500">Threshold: 15mm/s</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="font-medium">Cooling Tunnel</span>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Operation: 16hrs</span>
              <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Belt Speed</span>
              <div className="flex items-center space-x-2">
                <span className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-sm">
                  2.1 m/min
                </span>
                <span className="text-xs text-gray-500">Optimal: 2.0-2.2</span>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Zone Temperature</span>
              <div className="flex items-center space-x-2">
                <span className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-sm">
                  15.5°C
                </span>
                <span className="text-xs text-gray-500">Target: 15-16°C</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* AI Predictive Actions */}
    <div className="space-y-4">
      <h4 className="font-semibold mb-4">AI Predictive Maintenance</h4>
      <div className="bg-gradient-to-r from-blue-50 to-green-50 p-4 rounded-lg mb-4">
        <div className="flex justify-between items-center mb-3">
          <span className="font-medium">Current Status</span>
          <span className="text-green-600">All Systems Nominal</span>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="text-center">
            <p className="text-sm text-gray-600">Health Score</p>
            <p className="text-xl font-bold text-green-600">96%</p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">Next Service</p>
            <p className="text-xl font-bold text-blue-600">15d</p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">Alerts</p>
            <p className="text-xl font-bold text-yellow-600">2</p>
          </div>
        </div>
      </div>

      {/* Active AI Interventions */}
      <div className="space-y-3">
        <div className="bg-yellow-50 p-4 rounded-lg border-l-4 border-yellow-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-yellow-800 font-medium">Preventive Action Required</span>
            <span className="text-sm text-yellow-600">2hrs ago</span>
          </div>
          <p className="text-sm text-gray-700">Tempering machine bearing temperature trending upward</p>
          <div className="mt-2 flex justify-between items-center text-sm">
            <span className="text-yellow-600">Scheduled maintenance in 8hrs</span>
            <span className="text-green-600">Saving 4hrs downtime</span>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg border-l-4 border-blue-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-blue-800 font-medium">AI Optimization Active</span>
            <span className="text-sm text-blue-600">5min ago</span>
          </div>
          <p className="text-sm text-gray-700">Cooling tunnel belt speed auto-adjusted for optimal crystallization</p>
          <div className="mt-2 flex justify-between items-center text-sm">
            <span className="text-blue-600">Quality improvement: +2.5%</span>
            <span className="text-green-600">Reduced rejects</span>
          </div>
        </div>

        <div className="bg-green-50 p-4 rounded-lg border-l-4 border-green-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-green-800 font-medium">Maintenance Completed</span>
            <span className="text-sm text-green-600">1hr ago</span>
          </div>
          <p className="text-sm text-gray-700">Proactive motor bearing replacement on Tempering Unit #2</p>
          <div className="mt-2 flex justify-between items-center text-sm">
            <span className="text-green-600">Extended lifecycle: +3 months</span>
            <span className="text-green-600">Saved $12,000</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

     {/* Human Factor & Digital Workforce Section */}
<div className="bg-white p-6 rounded-lg shadow-lg mb-8">
  <div className="flex justify-between items-center mb-6">
    <div>
      <h3 className="text-xl font-bold">AI-Enhanced Digital Workforce</h3>
      <p className="text-gray-600">Augmenting Human Capabilities with AI & Robotics</p>
    </div>
    <div className="flex items-center space-x-2">
      <div className="px-3 py-1 bg-green-100 text-green-700 rounded-full flex items-center">
        <div className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
        75% Downtime Reduction
      </div>
      <div className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full">
        $3.2M Annual Savings
      </div>
    </div>
  </div>

  <div className="grid grid-cols-2 gap-8 mb-8">
    {/* AI-Robot Performance Stats */}
    <div>
      <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-lg mb-4">
        <h4 className="font-semibold mb-4">AI-Robot Task Performance</h4>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="bg-white p-3 rounded-lg">
            <p className="text-sm text-gray-600">Continuous Operation</p>
            <p className="text-2xl font-bold text-blue-600">24/7</p>
            <p className="text-xs text-blue-500">No Breaks Required</p>
          </div>
          <div className="bg-white p-3 rounded-lg">
            <p className="text-sm text-gray-600">Task Accuracy</p>
            <p className="text-2xl font-bold text-green-600">99.99%</p>
            <p className="text-xs text-green-500">Self-Improving</p>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={AIDowntimeData.humanFactorDowntime.monthlyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="manual" name="Manual Operations" fill="#EF4444" />
            <Bar dataKey="withAI" name="AI-Robot System" fill="#10B981" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>

    {/* Live Task Monitoring */}
    <div className="space-y-4">
      <div className="bg-blue-50 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-3">
          <h4 className="font-semibold text-blue-800">Active Robot Operations</h4>
          <span className="text-sm text-blue-600">All Systems Operational</span>
        </div>
        <div className="space-y-3">
          {/* Material Handling Robot */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <div>
                <span className="font-medium">Auto-Dispensing Robot</span>
                <p className="text-sm text-gray-600">Chocolate Production Line</p>
              </div>
              <span className="px-2 py-1 bg-green-100 text-green-600 rounded-full text-sm">Active</span>
            </div>
            <div className="text-sm text-gray-600">
              Current Task: Ingredient dispensing (4hrs continuous)
            </div>
            <div className="mt-2 text-sm text-green-600">
              No breaks needed - Saved 45 mins of human handover time
            </div>
          </div>

          {/* Packaging Robot */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <div>
                <span className="font-medium">Tray Management Robot</span>
                <p className="text-sm text-gray-600">Packaging Section</p>
              </div>
              <span className="px-2 py-1 bg-green-100 text-green-600 rounded-full text-sm">Active</span>
            </div>
            <div className="text-sm text-gray-600">
              Current Task: Continuous tray loading/unloading
            </div>
            <div className="mt-2 text-sm text-green-600">
              Replaced 3 shift rotations - 24/7 operation
            </div>
          </div>

          {/* Quality Control Robot */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <div>
                <span className="font-medium">AI Vision System</span>
                <p className="text-sm text-gray-600">Quality Control</p>
              </div>
              <span className="px-2 py-1 bg-green-100 text-green-600 rounded-full text-sm">Active</span>
            </div>
            <div className="text-sm text-gray-600">
              Current Task: Real-time product inspection
            </div>
            <div className="mt-2 text-sm text-green-600">
              5x faster than manual inspection, 99.99% accuracy
            </div>
          </div>
        </div>
      </div>

      {/* Downtime Prevention Stats */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <h4 className="font-semibold mb-3">Downtime Prevention Metrics</h4>
        <div className="space-y-3">
          <div className="flex justify-between items-center">
            <span>Shift Changeover Eliminated</span>
            <span className="text-green-600">45 mins/day saved</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Break Time Coverage</span>
            <span className="text-green-600">2 hrs/day saved</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Human Error Prevention</span>
            <span className="text-green-600">99.9% reduction</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Continuous Operation</span>
            <span className="text-green-600">24/7 achieved</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* AI-Robot Integration Benefits */}
  <div className="grid grid-cols-3 gap-4">
    <div className="bg-gradient-to-br from-blue-50 to-blue-100 p-4 rounded-lg">
      <h5 className="font-medium mb-2">No Human Limitations</h5>
      <p className="text-sm text-gray-600">Robots work continuously without breaks, vacations, or shift changes, ensuring consistent production flow.</p>
    </div>
    <div className="bg-gradient-to-br from-green-50 to-green-100 p-4 rounded-lg">
      <h5 className="font-medium mb-2">Perfect Precision</h5>
      <p className="text-sm text-gray-600">AI-guided robots maintain consistent quality and speed, eliminating human error-related downtime.</p>
    </div>
    <div className="bg-gradient-to-br from-purple-50 to-purple-100 p-4 rounded-lg">
      <h5 className="font-medium mb-2">Instant Adaptation</h5>
      <p className="text-sm text-gray-600">AI systems learn and optimize operations in real-time, preventing potential downtimes before they occur.</p>
    </div>
  </div>
</div>

      {/* Supply Chain Downtime Section */}
<div className="bg-white p-6 rounded-lg shadow-lg mb-8">
  <div className="flex justify-between items-center mb-6">
    <div>
      <h3 className="text-xl font-bold">Smart Supply Chain Management</h3>
      <p className="text-gray-600">AI-Powered Inventory & Material Control</p>
    </div>
    <div className="flex items-center space-x-2">
      <div className="px-3 py-1 bg-green-100 text-green-700 rounded-full">
        60% Downtime Reduction
      </div>
      <div className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full">
        $2.8M Saved/Year
      </div>
    </div>
  </div>

  <div className="grid grid-cols-2 gap-8">
    {/* Inventory Management AI */}
    <div className="bg-blue-50 p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-semibold text-blue-800">
          Real-time Material Management
        </h4>
        <div className="flex items-center">
          <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse mr-2"></div>
          <span className="text-sm text-green-700">AI Active</span>
        </div>
      </div>

      <div className="space-y-4">
        {/* Chocolate Production Materials */}
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="font-medium">Chocolate Base (Dark)</span>
            <span className={`px-2 py-1 rounded-full text-sm bg-green-100 text-green-700`}>
              85% Stock
            </span>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between text-sm text-gray-600">
              <span>Current Stock: 850 kg</span>
              <span>Daily Usage: ~100 kg</span>
            </div>
            <div className="text-sm bg-blue-50 p-2 rounded">
              <span className="font-medium">AI Action:</span> Auto-order scheduled in 5 days
            </div>
          </div>
        </div>

        {/* Packaging Components */}
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="font-medium">Box Packaging (200g size)</span>
            <span className={`px-2 py-1 rounded-full text-sm bg-yellow-100 text-yellow-700`}>
              35% Stock
            </span>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between text-sm text-gray-600">
              <span>Current Stock: 3,500 units</span>
              <span>Daily Usage: ~1,000 units</span>
            </div>
            <div className="text-sm bg-yellow-50 p-2 rounded">
              <span className="font-medium">AI Alert:</span> Order initiated - Critical in 2 days
            </div>
          </div>
        </div>

        {/* Production Supplies */}
        <div className="bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="font-medium">Foil Wrapping Material</span>
            <span className={`px-2 py-1 rounded-full text-sm bg-red-100 text-red-700`}>
              15% Stock
            </span>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between text-sm text-gray-600">
              <span>Current Stock: 15,000 sheets</span>
              <span>Daily Usage: ~5,000 sheets</span>
            </div>
            <div className="text-sm bg-red-50 p-2 rounded">
              <span className="font-medium">Emergency Action:</span> Express order placed automatically
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* AI Actions & Impact */}
    <div className="space-y-4">
      <h4 className="font-semibold mb-4">Real-time AI Interventions</h4>
      <div className="space-y-4">
        <div className="bg-gray-50 p-4 rounded-lg border-l-4 border-green-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-blue-600 font-medium">09:15 AM Today</span>
            <span className="text-green-600 font-bold">$12,000 Saved</span>
          </div>
          <p className="font-medium">Automated Material Management</p>
          <p className="text-sm text-gray-600 mt-1">AI detected low cocoa butter stock and placed order 2 weeks ahead of schedule, preventing production interruption</p>
          <div className="mt-2 bg-green-50 p-2 rounded text-sm">
            <span className="font-medium">Impact:</span> Prevented 48-hour production halt
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-blue-600 font-medium">11:30 AM Today</span>
            <span className="text-green-600 font-bold">$8,000 Saved</span>
          </div>
          <p className="font-medium">Supply Chain Optimization</p>
          <p className="text-sm text-gray-600 mt-1">Detected supplier delay in packaging material delivery. AI automatically sourced from backup supplier.</p>
          <div className="mt-2 bg-blue-50 p-2 rounded text-sm">
            <span className="font-medium">Prevention:</span> Avoided 2-day packaging delay
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border-l-4 border-purple-500">
          <div className="flex justify-between items-center mb-2">
            <span className="text-blue-600 font-medium">02:45 PM Today</span>
            <span className="text-green-600 font-bold">$5,000 Saved</span>
          </div>
          <p className="font-medium">Inventory Intelligence</p>
          <p className="text-sm text-gray-600 mt-1">AI optimized storage layout for new shipment arrival, maximizing space efficiency</p>
          <div className="mt-2 bg-purple-50 p-2 rounded text-sm">
            <span className="font-medium">Result:</span> 25% storage space optimization
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
             {/* Digital Workforce & AI Integration Section */}
<div className="bg-white p-6 rounded-lg shadow-lg mb-8">
  <div className="flex justify-between items-center mb-6">
    <div>
      <h3 className="text-xl font-bold">AI-Powered Digital Workforce</h3>
      <p className="text-gray-600">Next-Generation Automation with Intelligent Robots</p>
    </div>
    <div className="flex items-center space-x-2">
      <div className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full flex items-center">
        <div className="w-2 h-2 bg-purple-500 rounded-full mr-2 animate-pulse"></div>
        24/7 Operation
      </div>
      <div className="px-3 py-1 bg-green-100 text-green-700 rounded-full flex items-center">
        <div className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
        AI-Optimized
      </div>
    </div>
  </div>

  {/* Key Impact Metrics */}
  <div className="grid grid-cols-4 gap-4 mb-8">
    <div className="bg-gradient-to-br from-blue-50 to-blue-100 p-6 rounded-lg text-center transform hover:scale-105 transition-transform">
      <p className="text-sm text-gray-600">Production Uptime</p>
      <p className="text-3xl font-bold text-blue-600">
        {digitalWorkforceData.overallImpact.productionUptime}
      </p>
      <p className="text-xs text-blue-500 mt-1">AI-Maintained Consistency</p>
    </div>
    <div className="bg-gradient-to-br from-green-50 to-green-100 p-6 rounded-lg text-center transform hover:scale-105 transition-transform">
      <p className="text-sm text-gray-600">Tasks Automated</p>
      <p className="text-3xl font-bold text-green-600">
        {digitalWorkforceData.overallImpact.manualTasksAutomated}%
      </p>
      <p className="text-xs text-green-500 mt-1">Self-Learning Systems</p>
    </div>
    <div className="bg-gradient-to-br from-purple-50 to-purple-100 p-6 rounded-lg text-center transform hover:scale-105 transition-transform">
      <p className="text-sm text-gray-600">Annual Savings</p>
      <p className="text-3xl font-bold text-purple-600">
        {digitalWorkforceData.overallImpact.annualSavings}
      </p>
      <p className="text-xs text-purple-500 mt-1">Through AI Optimization</p>
    </div>
    <div className="bg-gradient-to-br from-yellow-50 to-yellow-100 p-6 rounded-lg text-center transform hover:scale-105 transition-transform">
      <p className="text-sm text-gray-600">Operation Hours</p>
      <p className="text-3xl font-bold text-yellow-600">
        {digitalWorkforceData.overallImpact.workforceEfficiency}
      </p>
      <p className="text-xs text-yellow-500 mt-1">Continuous Operation</p>
    </div>
  </div>

  {/* AI-Powered Robotic Systems */}
  <div className="grid grid-cols-2 gap-8 mb-8">
    {digitalWorkforceData.roboticSystems.map((robot, index) => (
      <div key={index} className="bg-gray-50 p-6 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h4 className="font-semibold text-lg">{robot.name}</h4>
          <span className="px-3 py-1 bg-green-100 text-green-700 rounded-full text-sm flex items-center">
            <div className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
            Active & Learning
          </span>
        </div>

        {/* AI Features */}
        <div className="mb-4">
          <h5 className="text-sm font-medium text-gray-600 mb-2">AI Capabilities</h5>
          <div className="grid grid-cols-2 gap-2">
            {robot.aiFeatures.map((feature, idx) => (
              <div key={idx} className="bg-blue-50 px-3 py-2 rounded text-sm text-blue-700">
                {feature}
              </div>
            ))}
          </div>
        </div>

        {/* Tasks & Impact */}
        <div className="space-y-3">
          {robot.tasks?.map((task, idx) => (
            <div key={idx} className="bg-white p-3 rounded-lg">
              <div className="flex justify-between items-center mb-1">
                <span className="font-medium">{task.name}</span>
                {task.downtimeReduction && (
                  <span className="text-green-600 text-sm">
                    -{task.downtimeReduction} Downtime
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-600">{task.aiCapability}</p>
            </div>
          ))}
        </div>

        {/* Impact Metrics */}
        <div className="mt-4 pt-4 border-t border-gray-200">
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(robot.impact).map(([key, value], idx) => (
              <div key={idx} className="text-sm">
                <span className="text-gray-600">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                <span className="font-medium ml-2 text-green-600">{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>

  {/* AI Learning & Optimization */}
  <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-6 rounded-lg">
    <h4 className="font-semibold mb-4">AI Learning & Continuous Improvement</h4>
    <div className="grid grid-cols-3 gap-6">
      {digitalWorkforceData.aiHighlights.map((highlight, index) => (
        <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
          <h5 className="font-medium text-blue-600 mb-2">{highlight.feature}</h5>
          <p className="text-sm text-gray-600 mb-2">{highlight.description}</p>
          <div className="text-sm bg-gray-50 p-2 rounded">
            <span className="text-gray-500">Example: </span>
            {highlight.example}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
    </div>
  );
}
